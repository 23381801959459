import Link from 'next/link';
import {
  getActiveSectionDetailsUrl,
  getRedirectFallbackToActiveSectionUrl,
} from '@utils/link-utils-web';
import Image from 'next/image';
import mascotSad from '../public/images/illustrations/mascotSad.png';
import MimoWebLogo from '@components/icons/mimoWebLogo';

export default function NotFound({ redirectUrl }: { redirectUrl?: string }) {
  return (
    <div className="flex min-h-screen flex-col items-center bg-product-background-primary-light p-8 dark:bg-product-background-primary-dark">
      <MimoWebLogo className="h-6 text-product-text-primary-light dark:text-product-text-primary-dark"></MimoWebLogo>
      <div className="my-auto flex flex-col items-center space-y-10">
        <Image src={mascotSad} className="h-40 w-40" alt="404"></Image>
        <h1 className="text-center text-4xl font-bold text-product-text-primary-light dark:text-product-text-primary-dark">
          Looks like there&apos;s nothing here.
        </h1>
        <Link
          href={
            redirectUrl ||
            getActiveSectionDetailsUrl() ||
            getRedirectFallbackToActiveSectionUrl()
          }
          className="rounded-lg bg-product-button-primary-enabled-light px-4 py-2 text-center text-xl text-product-text-reversed-light hover:bg-product-button-primary-hovered-light dark:bg-product-button-primary-enabled-dark dark:hover:bg-product-button-primary-hovered-dark"
        >
          Go back home
        </Link>
      </div>
    </div>
  );
}

export const getStaticProps = async () => {
  return {
    props: {},
  };
};
