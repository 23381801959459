import React from 'react';
import { useRouter } from 'next/router';
import { BuilderComponent, builder, useIsPreviewing } from '@builder.io/react';
import Head from 'next/head';
import { BuilderContent } from '@builder.io/sdk';
import { GetStaticProps } from 'next';
import '../builder-registry';
import NotFound from './404';
import Script from 'next/script';

builder.init(process.env.NEXT_PUBLIC_BUILDER_API_KEY!);

/**
 * The preview of the page model `local-development-page` is connected to localhost:3000.
 * With this, you can use the visual editor in builder.io and debug custom components more easily.
 * Make sure to select "Local Development Page" from the page models option. Your custom components
 * should appear there instantly. Don't forget to register them in `builder-registry.ts`.
 */
const pageModel =
  process.env.NODE_ENV === 'development' ? 'local-development-page' : 'page';

// Define a function that fetches the Builder
// content for a given page
export const getStaticProps: GetStaticProps = async ({ params }) => {
  // Fetch the builder content for the given page
  const page = await builder
    .get(pageModel, {
      userAttributes: {
        urlPath: '/' + ((params?.page as string[])?.join('/') || ''),
      },
      options: { includeUnpublished: true },
    })
    .toPromise();

  // Return the page content as props
  return {
    props: {
      page: page || null,
    },
    // Revalidate the content every 5 seconds
    revalidate: 5,
  };
};

// Define a function that generates the
// static paths for all pages in Builder
export async function getStaticPaths() {
  // Get a list of all pages in Builder
  const pages = await builder.getAll(pageModel, {
    // We only need the URL field
    fields: 'data.url',
    options: {
      noTargeting: true,
      includeUnpublished: !!process.env.BUILDER_IO_DEPLOYMENT,
    },
  });

  // Generate the static paths for all pages in Builder
  return {
    paths: pages
      .map((page) => String(page.data?.url))
      .filter((url) => url !== '/' && !url.includes('/glossary/')),
    fallback: process.env.BUILDER_IO_DEPLOYMENT ? 'blocking' : false,
  };
}

// Define the Page component
export default function Page({ page }: { page: BuilderContent | null }) {
  const router = useRouter();
  const isPreviewing = useIsPreviewing();

  // If the page content is not available
  // and not in preview mode, show a 404 error page
  if (!page && !isPreviewing) {
    return <NotFound />;
  }

  // If the page content is available, render
  // the BuilderComponent with the page content
  return (
    <>
      <Script
        id="cookieyes"
        type="text/javascript"
        src="https://cdn-cookieyes.com/client_data/3ac4d695a7fb984394170f54/script.js"
        strategy="beforeInteractive"
      />
      <Head>
        <title>{page?.data?.title}</title>
        <meta name="og:title" key="og:title" content={page?.data?.title} />
        <meta
          name="description"
          key="description"
          content={page?.data?.description}
        />
        <meta
          name="og:description"
          key="og:description"
          content={page?.data?.description}
        />
        <meta
          name="twitter:title"
          key="twitter:title"
          content={page?.data?.title}
        />
        <meta
          name="twitter:description"
          key="twitter:description"
          content={page?.data?.description}
        />
      </Head>
      {/* Render the Builder page */}
      <BuilderComponent model={pageModel} content={page || undefined} />
    </>
  );
}
