import { Builder } from '@builder.io/react';
import dynamic from 'next/dynamic';

Builder.registerComponent(
  dynamic(() => import('./components/web-landingpage/Footer')),
  {
    name: 'Footer',
    inputs: [],
  },
);

Builder.registerComponent(
  dynamic(() => import('./components/web-landingpage/NavBar')),
  {
    name: 'Navbar',
    inputs: [],
  },
);

Builder.registerComponent(
  dynamic(() => import('./components/web/pricing/FeaturedByCompanies')),
  {
    name: 'Featured Companies',
    inputs: [],
  },
);

Builder.registerComponent(
  dynamic(() => import('./components/builderio/MarkdownCodeBlockComponent')),
  {
    name: 'Markdown Code Block',
    inputs: [
      {
        name: 'content',
        type: 'longText',
        required: true,
      },
    ],
  },
);
